@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
body {
  font-family: "DM Sans", sans-serif;
}

option {
  color: black;
}

.pagination-button {
  display: inline-block;
  padding: 5px 3px;
  margin: 2px;
  border: none;
  background-color: var(--gray);
  color: var(--white);
  cursor: pointer;
  transition: "background-color 0.3s";
}

.pagination-button:hover {
  background-color: var(--gray-dark);
  transition: "background-color 0.3s";
}

.first-button {
  background-color: var(--primary);
}

.prev-button {
  background-color: var(--secondary);
}

.page-info {
  color: var(--dark);
  font-weight: bold;
}

.next-button {
  background-color: var(--success);
}

.last-button {
  background-color: var(--danger);
}

